<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("学习轨迹") }}
      <!-- 历史记录 -->
    </div>
    <div class="filter-wrap" style="position: relative" id="pageTop">
      <!-- 时间 -->
      <ListFilter
        only
        :name="$t('时间')"
        :allObj="{ name: $t('CM_All'), id: -1 }"
        :dataSource="recentYears"
        @onChange="switchYear"
      />
      <div class="totaltime">总时长：{{ formatTime(TotalTimes) }}</div>
    </div>
    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="newData"
    >
      <template #loadMore>
        <div class="load-more">
          <a-spin v-if="loading" />
          <div v-else-if="dataList.length <= 0"></div>
          <div v-else-if="finished">
            {{ $t("no_more") }}
            <!-- 没有更多了 -->
          </div>
          <a-button v-else type="primary" @click="loadMore" class="load-btn">
            {{ $t("load_more") }}
            <!-- 加载更多 -->
          </a-button>
        </div>
      </template>
      <template #renderItem="{ item }">
        <a-list-item>
          <div class="my-item">
            <div class="date">{{ item.date.replace(/-/g, "/") }}</div>
            <img
              class="clock"
              :src="require(`@/assets/image/mine/history_clock.png`)"
              alt="clock"
            />
            <div
              class="item"
              v-for="(ditem, dindex) in item.list"
              :key="dindex"
              @click="clickJump(ditem)"
            >
              <div class="time">
                {{ moment(ditem.startTime * 1000).format("HH:mm") }}
              </div>
              <div class="info">
                <div class="title">
                  【{{ getDetailType(ditem.resourceType) }}】{{
                    ditem.resourceName
                  }}
                </div>
                <div class="num" v-if="ditem.taskId > 0">
                  {{ $t("source_task") }}：【{{ ditem.resourceTypeName }}】{{
                    ditem.taskName
                  }}
                  <!-- 来源任务： -->
                </div>
                <div class="num" v-else-if="ditem.courseId > 0">
                  {{ $t("source_task") }}：【{{ ditem.resourceTypeName }}】{{
                    ditem.courseName
                  }}
                  <!-- 来源任务： -->
                </div>
              </div>
              <div class="duration">
                {{ $t("XB_LearningDuration") }}：{{
                  formatTime(ditem.learnTime)
                }}
                <!-- 学习时长： -->
              </div>
            </div>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { getLearnHistoryList, checkLearning } from "@/api/user";
import { formatTime } from "@/utils/tools";
import { getDetailType } from "@/utils/business";
import moment from "moment";
import ListFilter from "@/components/new/filter/ListFilter.vue";
export default {
  components: {
    ListFilter,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      recentYears: [],
      dataList: [],
      newData: [],
      searchParams: {
        page: 1,
        pageSize: 10,
      },
      Total: 0,
      TotalTimes: 0,
      loading: true, // 是否处于加载状态
      finished: false, // 是否全部加载完成
    });

    //上拉加载
    const loadMore = () => {
      state.loading = true;
      // 异步更新数据
      setTimeout(() => {
        getList();
      }, 100);
    };

    const getList = () => {
      getLearnHistoryList(state.searchParams).then((res) => {
        res.data.list = res.data.list || [];
        res.data.list.map((item) => {
          if (item.startTime != 0) {
            item.date = moment(item.startTime * 1000).format("YYYY-MM-DD");
          } else {
            item.date = 0;
          }
        });
        state.dataList.push.apply(state.dataList, res.data.list);
        state.newData = [];
        let startDate = "";
        for (let i = 0; i < state.dataList.length; i++) {
          let element = state.dataList[i];
          if (startDate !== element.date) {
            startDate = element.date;
            let tmpl = {};
            tmpl.date = element.date;
            tmpl.list = [];
            for (var j = 0; j < state.dataList.length; j++) {
              let jItem = state.dataList[j];
              if (element.date == jItem.date) {
                tmpl.list.push(jItem);
              }
            }
            state.newData.push(tmpl);
          }
        }
        state.searchParams.page += 1;
        // 加载状态结束
        state.loading = false;
        state.Total = res.data.totals;
        state.TotalTimes = res.data.totalTimes;
        // 数据全部加载完成
        if (state.dataList.length >= state.Total) {
          state.finished = true;
        }
      });
    };

    let urlStr = "";
    const clickJump = (item) => {
      let params = {};
      if (item.taskId) {
        params.taskId = item.taskId;
      } else {
        if (item.resourceType == 36) {
          params.knowledgeId = item.sectionId;
        } else {
          params.courseId = item.sectionId;
        }
      }
      checkLearning(params).then((res) => {
        if (res.ret == 0) {
          switch (item.resourceType) {
            case 1: //知识
              if (item.taskId > 0) {
                urlStr = `/doc/detail?taskId=${item.taskId}&sid=${item.stageId}&did=${item.detailId}`;
              } else {
                urlStr = `/doc/detail?id=${item.sectionId}&learnId=${item.learnId}`;
              }
              break;
            case 36: //知识
              if (item.taskId > 0) {
                urlStr = `/doc/detail?taskId=${item.taskId}&sid=${item.stageId}&did=${item.detailId}`;
              } else {
                urlStr = `/doc/detail?knowledgeId=${item.sectionId}`;
              }
              break;
            case 2: //课程
              if (item.taskId > 0) {
                urlStr = `/course/detail?taskId=${item.taskId}&sid=${item.stageId}&did=${item.detailId}`;
              } else {
                urlStr = `/course/detail?id=${item.sectionId}`;
              }
              break;
            case 3: //考试
              urlStr = `/exam/detail?id=${item.taskId}&did=${item.detailId}`;
              break;
            case 8: //面授
              urlStr = `/train/detail?id=${item.taskId}&did=${item.detailId}`;
              break;
          }
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
        }
      });
    };

    const getRecentFiveYears = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      // 从当前年份递减，获取近5年（含当前年）
      for (let year = currentYear; year >= currentYear - 4; year--) {
        years.push({id: year, name: year + $t("年")});
      }
      return years;
    };
    state.recentYears = getRecentFiveYears();

    getList();

    const switchYear = (arr) => {
      state.searchParams.page = 1;
      state.searchParams.year = arr[0];
      state.dataList = [];
      getList();
    };

    return {
      ...toRefs(state),
      moment,
      getDetailType,
      formatTime,
      loadMore,
      clickJump,
      getRecentFiveYears,
      switchYear,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);

  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 4px;
  }
  .filter {
    padding: 14px 0 40px;
  }
  .totaltime {
    position: absolute;
    right: 0px;
    top: 24px;
  }
  ::v-deep(.ant-list-empty-text) {
    padding: 150px;
    background: #fff;
    border-radius: 8px;
  }
  .ant-list-item {
    padding: 0;
    border-bottom: none;
  }
  .my-item {
    width: 100%;
    padding-bottom: 40px;
    margin-left: 104px;
    padding-left: 28px;
    border-left: 1px dashed #377dff;
    position: relative;
    .date {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      line-height: 26px;
      position: absolute;
      left: -104px;
      top: 0;
    }
    .clock {
      width: 32px;
      height: 32px;
      position: absolute;
      left: -16px;
      top: -3px;
    }
    .item {
      margin-bottom: 24px;
      .mixinFlex(space-between);
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      .time {
        color: #377dff;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        width: 56px;
      }
      .info {
        width: calc(100% - 256px);
        padding: 0 12px;
        font-size: 16px;
        line-height: 26px;
        .title {
          width: 500px;
          color: #333;
          transition: color 0.3s;
        }
        .num {
          color: #666;
          margin-top: 12px;
        }
      }
      .duration {
        font-size: 14px;
        line-height: 26px;
        color: #666;
        width: 200px;
        text-align: right;
      }
      &:hover {
        .info {
          .title {
            color: #377dff;
          }
        }
      }
    }
  }
  .ant-list-item:last-child {
    margin-bottom: 0;
    border-bottom: none !important;
    .my-item {
      padding-bottom: 0;
    }
  }
  .Nodata {
    text-align: center;
    padding: 10px;
    padding-top: 190px;
  }
  .Nodata .NodataImg {
    width: 160px;
  }
  .Nodata .NodataP {
    font-size: 10px;
    color: #c6c6c6;
  }
  .load-more {
    text-align: center;
    margin-top: 80px;
    height: 40px;
    line-height: 40px;
    color: "#BCBCBC";
    .load-btn {
      border-radius: 4px;
      background-color: #377dff;
    }
  }
}
</style>
